import { record } from "rrweb";
import rrwebPlayer from "rrweb-player";


let events = [];
let stopFn;

const button = document.createElement("button")
button.id = "btnPlay"
button.style.display = "none"
document.body.appendChild(button)
button.addEventListener('click', () => {
  stopFn();
  console.log('events',events)
  new rrwebPlayer({
    target: document.body, // customizable root element
    props: {
      events,
      UNSAFE_replayCanvas: true
    }
  });
})
stopFn = record({
    emit(event) {
      events.push(event);
      console.log("Event pushed");
    },
    recordCanvas: true,
    sampling: {
        canvas: 15,
    },
    slimDOMOptions: 'all'
  });

